






import { Component, Vue } from "vue-property-decorator";
import AdminShopArticleProducerTable from "@/components/admin/shop/article/producer/AdminShopArticleProducerTable.vue";

/**
 * AdminShopArticleProducerManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminShopArticleProducerTable,
    },
})
export default class AdminShopArticleProducerManagement extends Vue {}
