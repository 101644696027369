





















































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { ArticleProducer } from "@/types/shop/article";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";

/**
 * AdminShopArticleProducerTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
    },
})
export default class AdminShopArticleProducerTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private articleProducers: ArticleProducer[] = [];

    private defaultArticleProducerItem: ArticleProducer = {
        id: -1,
        name: "",
        homepage: "",
        description: "",
        image: {
            id: -1,
            src: "",
            alt: "",
        },
    };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$tc("language.name").toString(),
                value: "name",
            },
            {
                text: this.$t("shop.article.producer.homepage").toString(),
                value: "homepage",
            },
            {
                text: this.$tc("language.description").toString(),
                value: "description",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            this.articleProducers = await this.fetchArticleProducers();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all article producers
     *
     * @returns Promise<ArticleProducer[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticleProducers(): Promise<ArticleProducer[]> {
        const response = (
            await axios.get<APIResponse<ArticleProducer[]>>(
                "/articles/producers"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
